//import { initializeApp } from 'firebase/app'
//import {
//    getFirestore, onSnapshot, doc, getDoc, collection, query, where
//  } from 'firebase/firestore'
import { Environment } from './environment'
import TimeAgo from 'javascript-time-ago'

// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

// Create formatter (English).
const timeAgo = new TimeAgo('en-US')

timeAgo.format(new Date())
// "just now"

/**
 *                      Link Database
 */
window.env = Environment;
//const firebaseConfig = env.firebaseConfig;
//initializeApp(firebaseConfig);
//const db = getFirestore();
const dm = {
    cams: []
}



const bc1 = document.getElementById('bc1')
let anchorNow = "", anchorOld = "", hasNarrowScreen = false

// Function to handle changes based on screen width
function handleScreenWidth() {
    const screenWidth = window.innerWidth;

    // Your logic based on the screenWidth
    if (screenWidth <= 999) {
        // Do something for smaller screens
        hasNarrowScreen = true
    } else {
        // Do something for larget screens
        hasNarrowScreen = false
    }
}

// Event listener for the resize event
window.addEventListener('resize', handleScreenWidth);



/* * * * * * * * * * * 
 *  FETCH FUNCTIONS  *
 * * * * * * * * * * */


async function fetchMotionData() {
    let data = {}
    const myHeaders = new Headers({
        'Content-Type': 'application/json',

    });
    const response = await fetch(env.fetchUrl,  {
        headers: myHeaders
    });
    if(response.status===200) {
        data = await response.json();
    }  
    return data
}

async function updateMotionData(isNotInit=true) {
    //Get Motion data...
    let key, obj, dat, data, i, c=0, anc
    data = await fetchMotionData()
    for(i=0; i<data.length; i++){
        dat = data[i];
        dat.date = new Date(dat.eventTS)
        //Update anchor value if new motion
        if(dat.hasMotion==true && anchorOld != dat.srcID) {
            anc = dat.srcID
            c++
        }
    dm.cams[dat.srcID] = dat
    }
    //Clear anchor value if no motion
    if(c>0) {
        anchorOld = anchorNow
        anchorNow = anc
    } else {
        anchorNow = ""
    }
    //Build or update the object    
    updateView(isNotInit)
}  

function updateView(isNotInit) {
    let output = "", lblink= "", rblink = "", reverse = "", time, ago, camIDs, cam
    //camIDs = Object.keys(dm.cams)
    camIDs = Object.keys(dm.cams).sort((a, b) => dm.cams[a].srcName.localeCompare(dm.cams[b].srcName));
    camIDs.forEach((srcID)=>{
        cam = dm.cams[srcID]
        lblink = cam.hasMotion==true ? " lblink" : ""
        rblink = cam.hasMotion==true ? " rblink" : ""
        reverse = cam.hasMotion==true ? ' class="reverse"' : ''
        time = outputTime(cam.eventTS)
        ago = timeAgo.format(cam.date, 'round-minute')
        output += 
            `<div class="box">
             <a id="${cam.srcID}" name="${cam.srcID}">
             <a class="tnlink" href="${cam.srcUrl}" target="_blank">
             <div class="thumb-cntr">
               <img class="thumb" src="${cam.thumbnailUrl}"/>
             </div>
             <h2 ${reverse}>${cam.srcName}</h2></a>
             <div class="listContainer">
              <ul class="ulleft">
               <li><span>${time}</span></li>
               <li><span>${ago}</span></li>
              </ul>
              <ul>
                <li class="light-stand"><div class="housing"><span class="led${lblink}"></span></div>  <div class="housing"><span class="led${rblink}"></span></div></li>
                <li><img class="rrsign" src="railcrossingsign.jpg"/></li>
              </ul>
              </div>
            </div>`
    })
    bc1.innerHTML = output
    //Scroll to new motion item
    console.log("isNotInit", isNotInit, "anchorNow", anchorNow)
    if(isNotInit && hasNarrowScreen && anchorNow != "") {
        console.log("jumping to", anchorNow)
        window.location.hash = anchorNow
        //const element = document.getElementById(anchorNow)
        //element.scrollIntoView({behavior: "smooth"})
    }
}


function initApp() {
    handleScreenWidth();
    updateMotionData(false)
}

function formatDateParts(date) {
    var options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZone: 'America/Chicago', timeZoneName: 'short' };
    var formatter = new Intl.DateTimeFormat('en-US', options);
    var parts = formatter.formatToParts(date);
    var formattedDate = {};
    for (var i = 0; i < parts.length; i++) {
        formattedDate[parts[i].type] = parts[i].value;
    }
    return formattedDate;
}


function outputTime(timestamp) { 
    let now = new Date(timestamp);
    let fd = formatDateParts(now);
    let output = fd.month + ' '+ fd.day + ', ' +fd.year+ ' ' 
        + fd.hour+':'+fd.minute+fd.dayPeriod;
    return output
} 

function getKeyOfId(arr, id) {
  let key = -1;
  arr.forEach(function (currentVal, idx) {
    if(id == currentVal.id) {
      key = idx;
    }
  });
  return key;  
}


/**
 *         Run the app here 
 */
initApp()
setInterval(updateMotionData, 10000);
  